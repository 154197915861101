/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, img, ins, kbd, q, s, samp,
small, strike, tt, var,
b, u, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
  
}

u {
  pointer-events: none;
}

blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
html{
	font-family: Open Sans, sans-serif;
}

:root {
    --white: #fff;

		--black: #000;

    --green-400: #07cfc0;

		--red-300: #FF6347;
    --red-500: #f50057;

    --purple-500: #8774FF;
    --purple-900: #3f51b5;

		--yellow-500: #FCDE17;

    --gray-100: #909090;
    --gray-300: #606060;
    --gray-500: #545454;
    --gray-700: #424242;
    --gray-900: #303030;

    --error: #D53B30;
    --green-base: #55BE54;

    --footer-height: 70px;
    scroll-behavior: none;
}

*{
  font-family: 'Source Sans Pro', sans-serif;
}

:root {
	background-color: #121212;
	min-height: 100vh;
	/* transform: rotate(45deg); */
}

:root:before {
  content: "";
  position: fixed;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  z-index: -1;

  background-image: url('../assets/images/background_logo.png');
  transform: rotate(30deg);
}

    ::-webkit-scrollbar {
      width: 15px;
    }
    @media screen and (max-width: 850px) {
      ::-webkit-scrollbar {
          width: 10px;
          height: 10px;
        }
    }
  
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
  
    ::-webkit-scrollbar-thumb {
      background-color: var(--gray-500);

    }


/* Disable background changes on autofill inputs */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}

select {
  -webkit-appearance: none;
}

a[data-pending="true"] {
  pointer-events:none;
  text-decoration:none;
  color:black;
}